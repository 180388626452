import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/blog_3/b3_1.webp");
const image2 = require("../../../assets/images/blog_3/b3_2.jpg");
const image3 = require("../../../assets/images/blog_3/b3_3.jpg");
const image4 = require("../../../assets/images/blog_3/b3_4.jpg");
const image5 = require("../../../assets/images/blog_3/b3_5.jpg");
const image6 = require("../../../assets/images/blog_3/b3_6.jpg");
const image7 = require("../../../assets/images/blog_3/b3_7.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="How to build LastPass chatbot and automate user provisioning"
        description="Learn how LastPass customers are automating repetitive access management tasks using workativ assistant chatbot and streamline various employees IT requests such as Password Reset, User Provisioning, and Access Management via LastPass IT Chatbot."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Integrate LastPass with Workativ Assistant and
                            Automate User Provisioning
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Did you know that 50-60% of today’s organizations
                          still rely on manual Identity Access Management (IAM)?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          While password management technologies like{" "}
                          <a href="https://www.lastpass.com/products/business">
                            LastPass&nbsp;
                          </a>
                          enable administrators to provision and monitor users
                          across IAM applications, it requires admins to
                          manually change these factors for each individual
                          within the organization. This system can lead to
                          lapses in access restriction and ultimately large
                          losses in time, money, and security.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          A recent Forrester report examined the pitfalls of IAM
                          and the benefits of automating IAM processes.
                          According to the report, around 47% of technology
                          decision-makers have experienced internal data
                          breaches over the last 12 months. Of these
                          organizations, those which rely on IAM processes fall
                          victim to breaches more often than their competitors
                          who secure their organizations with automated
                          processes.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So why are people still not automating IAM?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          One of the primary reasons is the assumption that
                          automating IAM processes will not benefit the
                          organization’s bottom line. However, companies that
                          utilize IAM process automation can achieve over 100%
                          more ROI than they did with manual processes. Adopting
                          IAM process automation will reduce costs while
                          increasing return on investment. In fact, according to
                          the Forrester report, IAM costs can actually be double
                          that of automated IAM processes. The excess costs of
                          IAM can be attributed to the expense of IT hours
                          required to maintain the system and its
                          inefficiencies.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So what can you do? If you’re using LastPass, it’s
                          high time you automated your IAM processes. How, you
                          ask? Why with Workativ Assistant, of course.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is a no-code AI-powered SaaS{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            platform for building contextual chatbots with
                            automated workflows
                          </a>{" "}
                          for a wide range of apps. Workativ Assistant’s
                          chatbots can be added to any of your instant messaging
                          apps like Slack or Microsoft Teams so that employees
                          can self-serve their IT/HR issues, on-the-go, anytime.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          By combining LastPass with Workativ Assistant, you
                          make sure your employees have access to an{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            interactive IT self-service
                          </a>{" "}
                          interface instead of a static portal. Let’s take a
                          look at what all you can do with LastPass + Workativ
                          Assistant.
                        </p>

                        <p class="font-section-sub-header-small line-height-2">
                          AI-based IT Process Automation on Password Reset, User
                          Provisioning and Access Management via LastPass +
                          Workativ Integration
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          “30 percent of tickets are password-reset related
                          despite the fact that 69% of support teams allow their
                          customers to reset at least some of their passwords
                          without contacting the IT helpdesk”
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          <a href="https://www.thinkhdi.com/library/supportworld/2011/password-reset-practices.aspx">
                            {" "}
                            – HDI{" "}
                          </a>
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Most IT helpdesk tickets are actually low-value items
                          and typically repetitive. Often, they are issues
                          related to password resets. These are not issues that
                          require extensive expertise to resolve, but they are
                          still time-consuming and greatly diminishes the
                          productivity of your employees as well as helpdesk
                          teams. The subsequent outcome is that SLAs are often
                          missed and the cost per ticket rises rapidly.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          With LastPass + Workativ Assistant integration, you
                          can set up a LastPass chatbot that your employees can
                          use to reset their passwords, on-the-go, 24×7. This
                          enables your support team to spend more time on
                          addressing complex issues and high-value tickets.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Why stop with just password reset when you can do
                          more? By integrating LastPass with Workativ Assistant,
                          you ensure that help desk agents can securely and
                          easily add a user, update information about an
                          employee, retrieve details about an employee, or
                          remove an employee on LastPass—without leaving their
                          chat app.
                        </p>
                      </div>
                      <div>
                        <h2 class="font-section-sub-header-small">
                          Save time with LastPass + Workativ Assistant + Other
                          Apps Integration
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant, you can connect multiple apps
                          and set up an automation involving a synergistic
                          workflow of tasks on those apps. Let’s take a look at
                          some of the apps you can combine with Workativ
                          Assistant.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Okta and LastPass Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant’s{" "}
                          <a href="https://workativ.com/workflow-automation-platform/integrations/lastpass+okta">
                            LastPass and Okta integration,
                          </a>{" "}
                          you can set up an automation for a Workativ Assistant
                          chatbot such that, after retrieving the details about
                          the employee from Okta, the details get passed to the
                          LastPass action for creating a LastPass account for
                          the user. Saves valuable time for your employees as
                          well as your help desk agents.
                        </p>
                        <img loading="lazy" src={image2} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. LastPass and Office 365 Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Any help desk agent who has used Office 365 knows that
                          just adding a user to the company’s email list on
                          Office 365 involves a lot of manual work, be it
                          entering their details field after field or
                          communicating the same to the user via an email or
                          phone call. Whew, sounds like a lot of work, right?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          By connecting your{" "}
                          <a href="https://workativ.com/workflow-automation-platform/integrations/lastpass+office-365">
                            LastPass and Office 365
                          </a>{" "}
                          accounts with Workativ Assistant, you can set up a
                          chatbot automation such that, after getting details
                          about a user from LastPass, the details get passed to
                          Office 365 for adding them to the company’s email
                          list. Just like a breeze.
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          3. LastPass and Google Drive Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          By{" "}
                          <a href="https://workativ.com/workflow-automation-platform/integrations/lastpass+google-drive">
                            connecting your LastPass and Google Drive
                          </a>{" "}
                          accounts to Workativ Assistant, you can set up an
                          automation on Workativ Assistant such that, after
                          creating a LastPass account for a new employee, an
                          email gets sent to the employee with the Google Drive
                          link for the New Employee Guidelines document.
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          How you can easily create a LastPass Chatbot using
                          Workativ Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ Assistant and LastPass and your
                          existing apps can do for you, let’s see how you can go
                          about the same.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>

                        <img loading="lazy" src={image6} className="mb-3"></img>
                        <img loading="lazy" src={image7} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Perks of LastPass + Workativ Assistant
                      </h6>
                      <p class="font-section-normal-text line-height-2">
                        Let’s take a look at what the LastPass integration with
                        Workativ Assistant means for you.
                      </p>
                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Creating a Synergy between your existing Apps
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Like the Okta and LastPass integration worked
                          synergistically, you can combine your other existing
                          apps creating an automated workflow that does much
                          more with those apps in just a single run.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Intuitive conversational AI for an awesome employee
                          experience
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          No one prefers navigating through boring self-service
                          portals even if it’s LastPass. With Workativ
                          Assistant’s chatbots, provide the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                            seamless conversational self-service
                          </a>{" "}
                          that your employees would appreciate.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. All from the comfort of your chat hub
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s LastPass chatbot proactively
                          resides on your Slack/Microsoft Teams workspace
                          waiting to resolve your employees’ IT queries in an
                          instant, 24×7. Whether they are in the workplace or on
                          the road, employees can reset their password or do
                          anything on LastPass with Workativ Assistant.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. An exponential rise in employee productivity
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant’s LastPass chatbot{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            taking care of repetitive tasks
                          </a>{" "}
                          like password resets, your help desk agents can focus
                          on tasks that need a human touch and your employees
                          get the satisfaction of solving their issue
                          themselves, leading a greater increase in overall
                          productivity.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ Assistant? Try
                          Workativ Assistant today by signing up for a FREE
                          trial at{" "}
                          <a href="https://workativ.com/">workativ.com</a> and
                          experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 
            {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Lastpass Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="lastpass-chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
